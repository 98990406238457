import { ErrorCircle } from '@jsluna/icons'
import {
  Container,
  FilledButton,
  GridItem,
  GridWrapper,
  OutlinedButton,
} from '@jsluna/react'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { isFeatureEnabled } from '../../../common/api/featureApi'
import { useApiClient } from '../../../common/AppContext/appContext'
import BannerHolder from '../../../common/components/Banners/BannerHolder'
import ConfirmationBanner from '../../../common/components/Banners/ConfirmationBanner'
import ErrorBanner from '../../../common/components/Banners/ErrorBanner'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import Loading from '../../../common/components/Loading'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import {
  addBannerMessage,
  setBannerMessages,
  setPlanner,
} from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import { Feature } from '../../../common/enums/FeatureEnum'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  headerPlannerTitles,
} from '../../../common/enums/HeaderItems'
import { Menu } from '../../../common/enums/MenuEnum'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { IResult } from '../../../common/types/IResult'
import { getUserStore } from '../../../utils/localStore'
import { getCounters } from '../../api/counterApi'
import {
  createCounterAvailability,
  getBatches,
  getCounterAvailability,
} from '../../api/productionApi'
import { getStore } from '../../api/storeApi'
import { IBatch } from '../../types/IBatch'
import { ICounterAvailability } from '../../types/ICounterAvailability'
import ICounterCheck from '../../types/ICounterCheck'
import { IStackBanner } from '../../types/IStackBanner'
import { IStore } from '../../types/IStore'
import { ITemperatureData } from '../../types/ITemperatureData'
import {
  afternoonHour,
  getCurrentCheckedTimeInOrder,
  getTimeNow,
} from '../../utils/hotFoodUtils'
import AddNewBatchCard from './AddNewBatchCard'
import BatchCard from './BatchCard'
import CloseCounterModal from './CloseCounterModal'

const ProductionView = () => {
  const { dispatch, state } = useContext(Context)
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [batches, setBatches] = useState<IBatch[]>([])
  const [hasTurboServe, setHasTurboServe] = useState(false)
  const [isCloseCounterModalOpen, setIsCloseCounterModalOpen] = useState(false)
  const [isCounterClosed, setIsCounterClosed] = useState(false)
  const [isForecastingEnabled, setIsForecastingEnabled] = useState<
    boolean | null
  >(null)
  const [isServeOverTempCheckDue, setIsServeOverTempCheckDue] = useState(false)
  const [isTurboServeTempCheckDue, setIsTurboServeTempCheckDue] =
    useState(false)
  const [serveOverTempCheckDueTime, setServeOverTempCheckDueTime] = useState<
    moment.Moment | undefined
  >(undefined)
  const [serveOverTempNotTaken, setServeOverTempNotTaken] = useState(false)
  const [turboServeTempNotTaken, setTurboServeTempNotTaken] = useState(false)
  const [showBatchTimes, setShowBatchTimes] = useState(false)
  const [lunchOvenStartTime, setLunchOvenStartTime] = useState<
    Date | undefined
  >(undefined)
  const [headerNavigationItems, setHeaderNavigationItems] = useState(
    headerMainMenus.hotfoodcounterNavs,
  )
  let varServeOverTempCheckDueTime: moment.Moment | undefined
  let varMissingTurboServePmTempChecks = false

  const apiClient = useApiClient()

  useEffect(() => {
    const store = getUserStore()

    GetHeaderMainMenus(apiClient, store.storeId, PlannerName.HotFood).then(
      (res) => setHeaderNavigationItems(res),
    )

    dispatch(setPlanner(PlannerName.HotFood))

    const getForecastingTask = isFeatureEnabled(
      apiClient,
      Feature.HotFoodBatches,
      store.storeId,
    )
    const getStoreTask = getStore(apiClient, store.storeId)
    const getCounterAvailabilityTask = getCounterAvailability(
      apiClient,
      store.storeId,
      new Date(),
    )
    const getBatchesTask = getBatches(apiClient, store.storeId, new Date())
    const getCountersTask = getCounters(
      apiClient,
      store.storeId,
      moment(new Date(Date.now())).toDate(),
    )
    const getTutorialTokenTask = apiClient.getTutorialAccessToken()

    Promise.all([
      getStoreTask,
      getCounterAvailabilityTask,
      getBatchesTask,
      getCountersTask,
      getForecastingTask,
      getTutorialTokenTask,
    ])
      .then(
        (
          responses: [
            IStore,
            ICounterAvailability,
            IBatch[],
            ICounterCheck[],
            boolean,
            string | null,
          ],
        ) => {
          setHasTurboServe(responses[0].serveOver && responses[0].turboServe)
          setShowBatchTimes(responses[0].showBatchTimes)
          setIsCounterClosed(!responses[1].isActive)
          setIsForecastingEnabled(responses[4])
          setTutorialToken(responses[5])

          const [planBatches, userBatches] = responses[2].reduce(
            (acc: IBatch[][], i: IBatch) => (
              acc[!i.isUserCreated ? 0 : 1].push(i), acc
            ),
            [[], []],
          )

          // Convenience Stores (local) : add index of the autogenerated batches by cronjob to use at the title index
          const sortedPlanBatches = !responses[0].showBatchTimes
            ? planBatches
                .filter((b) => b.programId !== null)
                .sort((a, b) =>
                  a.productionTime === undefined
                    ? 1
                    : b.productionTime === undefined
                      ? -1
                      : a.productionTime > b.productionTime
                        ? 1
                        : -1,
                )
                .reduce((accBatches: IBatch[], curBatch: IBatch) => {
                  const lastIndex =
                    (accBatches[accBatches.length - 1]
                      ?.planBatchIndex as number) || 0
                  curBatch.planBatchIndex = lastIndex + 1
                  return [...accBatches, curBatch]
                }, [])
            : planBatches
                .filter((b) => b.programId !== null)
                .sort((a, b) =>
                  a.productionTime === undefined
                    ? 1
                    : b.productionTime === undefined
                      ? -1
                      : a.productionTime > b.productionTime
                        ? 1
                        : -1,
                )

          // set lunch oven start time based on the production time of the first lunch batch
          // exeptional case (unrealistic case) : a breakfast batch remains, but all done for lunch batches
          // -> set 'lunch oven start time' to 'last breakfast batch time + min cooktime(10min) of programs'
          const firstLunchBatchTime =
            !sortedPlanBatches || sortedPlanBatches.length === 0
              ? undefined
              : sortedPlanBatches.filter((b) => !b.isBreakfastLine).length > 0
                ? (sortedPlanBatches.filter((b) => !b.isBreakfastLine)[0]
                    .productionTime as Date)
                : dayjs(
                    sortedPlanBatches.filter((b) => b.isBreakfastLine).pop()
                      ?.productionTime,
                  )
                    .add(10, 'minutes')
                    .toDate()

          setLunchOvenStartTime(firstLunchBatchTime)

          // split userBatches by the production time of the first lunch batch
          const splitUserBatches = userBatches.reduce(
            (acc: IBatch[], cur: IBatch) => [
              ...acc,
              {
                ...cur,
                isBreakfastLine:
                  !firstLunchBatchTime || !cur.productionTime
                    ? undefined
                    : dayjs(firstLunchBatchTime).diff(
                        dayjs(userBatches[0].productionTime),
                      ) > 0,
              },
            ],
            [],
          )

          setBatches([...splitUserBatches, ...sortedPlanBatches])

          const serveOver: ICounterCheck[] = []
          const turboServe: ICounterCheck[] = []

          responses[3]
            .filter(
              (counter) =>
                !counter.turboServe &&
                !counter.isFaulty &&
                counter.takenAt !== null,
            )
            .map((counter) => {
              serveOver.push({
                counterNumber: counter.counterNumber,
                isFaulty: counter.isFaulty,
                takenAt: counter.takenAt,
                turboServe: false,
              })
            })

          responses[3]
            .filter((counter) => counter.turboServe && !counter.isFaulty)
            .map((counter) => {
              turboServe.push({
                counterNumber: counter.counterNumber,
                isFaulty: counter.isFaulty,
                takenAt: counter.takenAt,
                temperatures: undefined,
                turboServe: true,
              })
            })

          // if ANY temperature taken for today, get earliest time due, adding two hours
          if (serveOver.length > 0) {
            varServeOverTempCheckDueTime = getCurrentCheckedTimeInOrder(
              serveOver,
              false,
              true,
              true,
            ).add(2, 'hours')
            setServeOverTempCheckDueTime(varServeOverTempCheckDueTime)
            serveOverBannerCheck()
          }

          // get unique counter numbers
          const counterNumbers = [
            ...new Set(turboServe.map((counter) => counter.counterNumber)),
          ]
          counterNumbers.map((num) => {
            // if no PM check exists for any of the unique counter(s), flag as missing
            varMissingTurboServePmTempChecks =
              varMissingTurboServePmTempChecks ||
              turboServe.filter(
                (counter) =>
                  counter.counterNumber === num &&
                  counter.takenAt !== null &&
                  moment.utc(counter.takenAt).local().get('hours') >=
                    afternoonHour,
              ).length === 0
          })

          const okCounters = responses[3].filter((counter) => !counter.isFaulty)
          setServeOverTempNotTaken(
            okCounters.filter(
              (counter) => !counter.turboServe && counter.takenAt === null,
            ).length > 0,
          )
          setTurboServeTempNotTaken(
            okCounters.filter(
              (counter) => counter.turboServe && counter.takenAt === null,
            ).length > 0,
          )

          if (varMissingTurboServePmTempChecks) {
            turboServeBannerCheck()
          }
        },
      )
      .catch((e: unknown) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(e)
        }
        setErrorMessage(
          `${ErrorMessage.ProductionView.FailedToLoad} - ${(e as Error).message}`,
        )
      })
      .finally(() => {
        setIsLoading(false)
        setInterval(refresh, 10000)
      })
  }, [])

  const onCounterAvailabilityChange = async (
    isActive: boolean,
    reasonId: number,
    reasonDescription: string,
  ) => {
    if (isCloseCounterModalOpen) {
      setIsCloseCounterModalOpen(false)
    }

    const counterAvailability: ICounterAvailability = {
      isActive,
      reasonDescription,
      reasonId,
      storeId: getUserStore().storeId,
    }

    await createCounterAvailability(apiClient, counterAvailability)
      .then((res: IResult) => {
        if (res.isSuccess) {
          dispatch(
            addBannerMessage(
              <ConfirmationBanner
                message={`Hot food counter has been ${isActive ? 're-opened' : 'closed'}.`}
              />,
            ),
          )
        } else {
          setErrorMessage(ErrorMessage.SaveException)
        }
      })
      .catch(() => setErrorMessage(ErrorMessage.SaveException))

    setIsCounterClosed(!isActive)
  }

  const refresh = () => {
    serveOverBannerCheck()
    turboServeBannerCheck()
  }

  const serveOverBannerCheck = () => {
    if (
      varServeOverTempCheckDueTime !== undefined &&
      varServeOverTempCheckDueTime.toDate() <= getTimeNow()
    ) {
      setIsServeOverTempCheckDue(true)
    }
  }

  const turboServeBannerCheck = () => {
    if (
      varMissingTurboServePmTempChecks &&
      getTimeNow().getHours() >= afternoonHour
    ) {
      setIsTurboServeTempCheckDue(true)
    }
  }

  const removeBatch = (batchId: number) =>
    setBatches(batches.filter((b: IBatch) => b.batchId !== batchId))

  const updateTemperatures = (
    batchId: number,
    temperatures: ITemperatureData[],
  ) => {
    batches.filter((b: IBatch) => b.batchId === batchId)[0].temperatures =
      temperatures.filter((t) => t.temperature !== '')
    setBatches(batches)
  }

  const updateTemperaturesCount = (
    batchId: number,
    temperaturesCount: number,
  ) => {
    batches.filter((b: IBatch) => b.batchId === batchId)[0].temperaturesCount =
      temperaturesCount
    setBatches(batches)
  }

  const counterTemperatureLink = (): JSX.Element => (
    <Link
      to={{
        pathname: '/hotfoodcountertemperature',
        search: '',
      }}
    >
      Counter temperature
    </Link>
  )

  const saveBanners = (banners: IStackBanner[]) => {
    dispatch(setBannerMessages(banners))
  }

  const isBreakfastOrLunchBatch = () =>
    // can show the 'add' button and user-batches even if
    // - there is no batche at all or
    // - no plan-batche or
    // - any undefined in isBreakfastLine flag of batches
    lunchOvenStartTime &&
    batches.length > 0 &&
    batches.filter((b: IBatch) => !b.isUserCreated).length > 0 &&
    batches.filter(
      (b: IBatch) => b.isBreakfastLine === true || b.isBreakfastLine === false,
    ).length > 0

  const buildBannerMessageForNotTaken = () => {
    const headerMessage =
      serveOverTempNotTaken && turboServeTempNotTaken
        ? ' both counter temperature checks '
        : !serveOverTempNotTaken
          ? ' your AM turbo-serve temperature check '
          : ' your first cabinet temperature checks '
    return (
      <>
        Please complete {headerMessage}
        in the '{counterTemperatureLink()}' tab before adding any products to
        the display.
      </>
    )
  }

  return (
    <>
      {!tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper
          state={state}
          token={tutorialToken || ''}
          enabledFeatures={{ forecasting: isForecastingEnabled || false }}
        >
          <div className='c-common-main-view-content'>
            <Header
              title={headerPlannerTitles.hotfood}
              navItems={headerNavigationItems}
              activeMenuType={Menu.ProductionView}
            />
            <Container soft className='ln-u-push-top-sm'>
              {isLoading && <Loading />}

              {!isLoading && errorMessage && (
                <ErrorRefresh message={errorMessage} />
              )}

              {!isLoading && !errorMessage && (
                <>
                  {(serveOverTempNotTaken || turboServeTempNotTaken) &&
                    !isCounterClosed && (
                      <ErrorBanner
                        icon={<ErrorCircle />}
                        message={''}
                        messageObject={buildBannerMessageForNotTaken()}
                      />
                    )}

                  {isServeOverTempCheckDue && !isCounterClosed && (
                    <ErrorBanner
                      icon={<ErrorCircle />}
                      message={''}
                      messageObject={
                        <>
                          {`Your next counter check is due by ${serveOverTempCheckDueTime!.format('HH:mm')}.
                        You can complete it in the '`}
                          {counterTemperatureLink()}' tab.
                        </>
                      }
                    />
                  )}

                  {isTurboServeTempCheckDue && !isCounterClosed && (
                    <ErrorBanner
                      icon={<ErrorCircle />}
                      message={''}
                      messageObject={
                        <>
                          Please complete your PM turbo-serve temperature
                          reading in the '{counterTemperatureLink()}' tab by the
                          end of the day.
                        </>
                      }
                    />
                  )}

                  <BannerHolder
                    banners={state.bannerMessages}
                    setBanners={saveBanners}
                  />
                  <div className='ln-u-margin-bottom'></div>

                  <GridWrapper>
                    <GridItem
                      size={{
                        xs: '1/2',
                        ss: '1/2',
                        sm: '1/2',
                        md: '1/2',
                        lg: '1/2',
                      }}
                    >
                      <h4>Today's production</h4>
                      <div className='ln-u-body-1-fixed ln-u-push-bottom-sm'>
                        {`${moment(new Date(Date.now())).format('dddd Do MMMM YYYY')}.`}
                      </div>
                    </GridItem>

                    {!isCounterClosed && (
                      <GridItem
                        size={{
                          xs: '1/2',
                          ss: '1/2',
                          sm: '1/2',
                          md: '1/2',
                          lg: '1/2',
                        }}
                      >
                        <OutlinedButton
                          className='c-availability-button ln-u-margin-bottom*2'
                          onClick={() => setIsCloseCounterModalOpen(true)}
                        >
                          Set counter to closed
                        </OutlinedButton>
                      </GridItem>
                    )}
                  </GridWrapper>

                  {!isCounterClosed && (
                    <>
                      {!isBreakfastOrLunchBatch() ? (
                        <>
                          {batches?.map((batch: IBatch, i: number) => (
                            <GridItem
                              key={i}
                              size={{ default: '1/1', md: '1/2', lg: '1/3' }}
                              className='ln-u-hard'
                            >
                              <BatchCard
                                batch={batch}
                                batchIndex={batch.planBatchIndex}
                                counterTemperaturesNotTaken={
                                  serveOverTempNotTaken ||
                                  turboServeTempNotTaken
                                }
                                hasTurboServe={hasTurboServe}
                                removeBatch={removeBatch}
                                updateTemperatures={updateTemperatures}
                                updateTemperaturesCount={
                                  updateTemperaturesCount
                                }
                                setErrorMessage={setErrorMessage}
                                showBatchTimes={showBatchTimes}
                              />
                            </GridItem>
                          ))}
                          <GridItem
                            size={{ default: '1/1', md: '1/2', lg: '1/3' }}
                            className='ln-u-hard'
                          >
                            <AddNewBatchCard />
                          </GridItem>
                        </>
                      ) : (
                        <>
                          {batches.filter(
                            (b: IBatch) => b.isBreakfastLine === true,
                          ).length > 0 && (
                            <>
                              <h5>Breakfast</h5>
                              {batches
                                .filter((b: IBatch) => b.isBreakfastLine)
                                .map((batch: IBatch, i: number) => (
                                  <GridItem
                                    key={i}
                                    size={{
                                      default: '1/1',
                                      md: '1/2',
                                      lg: '1/3',
                                    }}
                                    className='ln-u-hard'
                                  >
                                    <BatchCard
                                      batch={batch}
                                      batchIndex={batch.planBatchIndex}
                                      counterTemperaturesNotTaken={
                                        serveOverTempNotTaken ||
                                        turboServeTempNotTaken
                                      }
                                      hasTurboServe={hasTurboServe}
                                      removeBatch={removeBatch}
                                      updateTemperatures={updateTemperatures}
                                      updateTemperaturesCount={
                                        updateTemperaturesCount
                                      }
                                      setErrorMessage={setErrorMessage}
                                      showBatchTimes={showBatchTimes}
                                    />
                                  </GridItem>
                                ))}
                              <GridItem
                                size={{ default: '1/1', md: '1/2', lg: '1/3' }}
                                className='ln-u-hard'
                              >
                                <AddNewBatchCard />
                              </GridItem>
                            </>
                          )}

                          {batches.filter(
                            (b: IBatch) => b.isBreakfastLine === false,
                          ).length > 0 && (
                            <>
                              <h5>Lunch</h5>
                              {batches
                                .filter((b) => !b.isBreakfastLine)
                                .map((batch: IBatch, i: number) => (
                                  <GridItem
                                    key={i}
                                    size={{
                                      default: '1/1',
                                      md: '1/2',
                                      lg: '1/3',
                                    }}
                                    className='ln-u-hard'
                                  >
                                    <BatchCard
                                      batch={batch}
                                      batchIndex={batch.planBatchIndex}
                                      counterTemperaturesNotTaken={
                                        serveOverTempNotTaken ||
                                        turboServeTempNotTaken
                                      }
                                      hasTurboServe={hasTurboServe}
                                      removeBatch={removeBatch}
                                      updateTemperatures={updateTemperatures}
                                      updateTemperaturesCount={
                                        updateTemperaturesCount
                                      }
                                      setErrorMessage={setErrorMessage}
                                      showBatchTimes={showBatchTimes}
                                    />
                                  </GridItem>
                                ))}

                              {/* show the button only when there are no breakfast lines */}
                              {batches.filter((b: IBatch) => b.isBreakfastLine)
                                .length === 0 && (
                                <GridItem
                                  size={{
                                    default: '1/1',
                                    md: '1/2',
                                    lg: '1/3',
                                  }}
                                  className='ln-u-hard'
                                >
                                  <AddNewBatchCard />
                                </GridItem>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {isCounterClosed && (
                    <div className='c-no-availability'>
                      <ErrorCircle className='c-no-availability-icon' />
                      <span className='c-no-availability-header-text'>
                        Your counter has been marked as closed
                      </span>
                      <div className='c-no-availability-text'>
                        There are no batches available as this counter has been
                        marked as closed. You can re-open the counter in the
                        button below once it is fixed or available for use
                        again.
                      </div>
                      <FilledButton
                        className='c-no-availability-button'
                        onClick={() => onCounterAvailabilityChange(true, 0, '')}
                      >
                        Re-open counter
                      </FilledButton>
                    </div>
                  )}
                </>
              )}

              {isCloseCounterModalOpen && (
                <CloseCounterModal
                  handleClose={() => setIsCloseCounterModalOpen(false)}
                  handleSave={(reasonId: number, reasonDescription: string) =>
                    onCounterAvailabilityChange(
                      false,
                      reasonId,
                      reasonDescription,
                    )
                  }
                  isOpen={isCloseCounterModalOpen}
                />
              )}
            </Container>
          </div>
        </TutorialWrapper>
      )}
    </>
  )
}

export default ProductionView
